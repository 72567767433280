import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-131"
        className="post-131 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Nightclub Two Step</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            Nightclub Two-step is een vlotte, sociale dans die gedanst wordt op
            leuke, langzame muziek (Vb. Chris de Burgh – The Lady in Red, Ed
            Sheeran – Thinking out loud, …).
          </p>
          <p>
            Het is bedacht in 1960 door Buddy Schwimmer&nbsp; en wordt gedanst
            op muziek met een 4/4 maat en een tempo van 16-22 maten per minuut.
            Het basis ritme is Quick Quick Slow.
          </p>
          <p>
            Door oa. het ritme lijkt&nbsp;het een beetje op rumba, maar toch is
            het een hele andere dans. Je danst hier 2 keer zo snel op dezelfde
            muziek.
          </p>
          <p>
            Vandaar dat langzame muziek erg geschikt is voor de Nightclub
            Two-step.
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
